<template>
  <b-card no-body class="border-primary">
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0">Facilities</h5>
      <b-badge variant="light-primary"> Basic </b-badge>
      <small class="text-muted w-100">July 22, 2021</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-2 facility">
        <li v-for="facility in facilities" :key="facility.id">
          <b-badge
            variant="light-primary"
            :to="{ name: 'admin-facilities-view', params: { id: facility.facilities_id } }"
            ><span>{{ facility.name }}</span></b-badge
          >
        </li>
      </ul>
      <b-button
        variant="primary"
        block
        :to="{ name: 'admin-facilities' }" 
      >
        View Facilities
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    facilities: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.facility {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
